import { IRequest, ReportTaxSettlementsTypeDirect } from "@dltru/dfa-models";
import { requestDecorator } from "..";
import { HttpCode, HttpMethod } from "../../types/types";

type PostReportTaxSettlementsTypeDirectResponse = {
  file: string,
  file_name: string
}

export async function postReportTaxSettlementsTypeDirect(
  data: ReportTaxSettlementsTypeDirect,
): Promise<IRequest<PostReportTaxSettlementsTypeDirectResponse>> {
  return requestDecorator.call(
    this,
    `${this.paths.ReportTaxSettlementsTypeDirect}`,
    HttpMethod.POST,
    HttpCode.SUCCESS_200,
    { data }
  )
}