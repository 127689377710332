import { IOnceInNMonthsFrequencyData, IRequest, ResponseItems } from "@dltru/dfa-models";
import { requestDecorator } from "..";
import { HttpCode, HttpMethod } from "../../types/types";

export async function getCouponPaymentCalendar(params: IOnceInNMonthsFrequencyData): Promise<IRequest<ResponseItems<string>>> {
  return requestDecorator.call(
    this,
    this.paths.CalendarCalculateCouponPayment,
    HttpMethod.GET,
    HttpCode.SUCCESS_200,
    { params }
  )
}